import React from 'react'
import Bradcom from '../../Bradcom/Main'
import Bal1 from "../../../assets/images/services/bal-gala/1.jpg";
import Gala1 from "../../../assets/images/services/bal-gala/2.jpg";
import BalIcon from "../../../assets/images/bg/bal-icon.png";
import CTA from "../../CTA/CTA";

const Main = () => {
    return (
        <>
            <main>
                <Bradcom title={"Prestations : Bals / Galas"}
                         subtitle={"DJ Len à votre service !"}/>

                <section className="about-area about-p pt-120 pb-50 p-relative fix">
                    <div className="animations-02"><img src={BalIcon} alt="an-img-01"/></div>

                    <div className="container">
                        <div className="row justify-content-center align-items-center">

                            <div className="col-lg-6 col-md-12 col-sm-12 pr-30">
                                <div className="s-about-img p-relative  wow fadeInLeft animated"
                                     data-animation="fadeInLeft" data-delay=".4s">
                                    <img style={{marginBottom: "0px", borderRadius: '25px'}} src={Bal1} alt="img"/>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="feature-content s-about-content">
                                    <div className="feature-title pb-20">
                                        <h4>Bals des conscrits 🍾</h4>
                                    </div>
                                    <p><b>Notre engagement : une qualité audiovisuelle d'exception, tout en
                                        respectant scrupuleusement le budget qui vous est alloué.</b> Forts de notre
                                        savoir-faire en gestion événementielle, nous créons des expériences mémorables
                                        pour vos célébrations de Conscrits.
                                    </p>
                                    <p>

                                        Peu importe le thème que vous envisagez pour votre bal, notre talent réside dans
                                        la création d'une ambiance captivante et festive, parfaitement ajustée à vos
                                        désirs et en harmonie avec vos contraintes budgétaires. Pour garantir un bal des
                                        Conscrits réussi sans le moindre tracas technique ni la moindre fausse note,
                                        nous vous invitons à nous accorder votre confiance. Profitez de solutions sur
                                        mesure, spécialement conçues pour répondre à vos besoins et surpasser vos
                                        attentes, et faites de votre bal des Conscrits un événement inoubliable.
                                    </p>

                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section className="about-area about-p pt-50 pb-120 p-relative fix">
                    <div className="animations-01"><img src={BalIcon} alt="an-img-01"/></div>

                    <div className="container">
                        <div className="row justify-content-center align-items-center">


                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="feature-content s-about-content">
                                    <div className="feature-title pb-20">
                                        <h4>Galas Étudiant / Week-ends d'intégrations 🎉</h4>
                                    </div>
                                    <p><b>Notre engagement : une qualité
                                        audiovisuelle exceptionnelle, méticuleusement calibrée pour s'intégrer à votre
                                        enveloppe budgétaire.</b> Portés par une solide expérience en gestion
                                        événementielle, nous créons des expériences gravées dans les mémoires pour vos
                                        festivités estudiantines.
                                    </p>
                                    <p>
                                        Peu importe le thème que vous rêvez de donner à votre gala ou à votre week-end
                                        d'intégration, notre véritable génie réside dans l'art de créer une ambiance
                                        immersive et festive, entièrement conçue pour refléter vos aspirations et
                                        contraintes financières. Pour garantir le succès incontestable de vos
                                        événements, sans le moindre tracas technique ni la moindre discordance, nous
                                        vous incitons à nous confier votre confiance. Découvrez des solutions sur
                                        mesure, spécifiquement adaptées pour répondre à vos besoins spécifiques et
                                        surpasser vos attentes. Faites du Gala Étudiant ou du Week-end d'Intégration des
                                        moments uniques grâce à notre expertise exceptionnelle.
                                    </p>

                                </div>
                            </div>


                            <div className="col-lg-6 col-md-12 col-sm-12 pl-30">
                                <div className="s-about-img p-relative  wow fadeInLeft animated"
                                     data-animation="fadeInLeft" data-delay=".4s">
                                    <img style={{marginBottom: "0px", borderRadius: '25px'}} src={Gala1} alt="img"/>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <CTA/>
            </main>
        </>
    )
}

export default Main