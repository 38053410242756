import React from 'react'
import {Link} from 'react-router-dom'
import Icon from "../../assets/images/services/bg.png"
import Home1 from "../../assets/images/home/home-1.png"
import Home2 from "../../assets/images/home/home-2.jpeg"

function Most() {
    return (
        <>
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02"><img src={Icon} alt="contact-bg-an-02"/></div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft"
                                 data-delay=".4s">
                                <img src={Home1} alt="img" style={{borderRadius: '15px'}} width={'450px'}/>
                                <div className="about-icon">
                                    <img src={Home2} alt="img" style={{borderRadius: '15px'}} width={'350px'}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="about-content s-about-content  wow fadeInRight  animated pl-30"
                                 data-animation="fadeInRight" data-delay=".4s">
                                <div className="about-title second-title pb-25">
                                    <h2 style={{fontSize: '45px'}}>Découvrez DJ LEN,</h2>
                                    <h3>en quelques lignes...</h3>
                                </div>
                                <p>Depuis 2022, DJ Len est une jeune équipe à l'écoute et motivée pour animer
                                    vos soirées d'anniversaires, mariages et tous vos événements festifs comme sportifs.
                                    Basée dans la Loire, disponible aux alentours de Ampuis, Vienne, Saint-Etienne et
                                    même Lyon et sa périphérie. Un
                                    savoir faire et un matèriel de qualité pour satisfaire tout vos besoins, alors
                                    n'hésitez pas à nous contacter dès maintenant.</p>

                                <p style={{fontSize: '16px'}}><b>Un peu de nous, beaucoup de vous !</b> ✨</p>
                                <div className="about-content3 mt-30">
                                    <div className="slider-btn">
                                        <Link to="/contact"
                                              className="btn ss-btn smoth-scroll">Contactez-nous</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Most