import {Link} from 'react-router-dom'
import React from 'react'
import Contact1 from "../../assets/images/bg/contact-icon.png";

const CTA = () => {
    return (
        <>
            <main>
                <section className="newslater-area p-relative pt-120 pb-120" style={{background: "#00000012"}}>
                    <div className="animations-01"><img src={Contact1} alt="contact-bg-an-05"/></div>
                    <div className="container">
                        <div className="row justify-content-center align-items-center text-center">
                            <div className="col-xl-9 col-lg-9">
                                <div className="section-title center-align mb-40 text-center wow fadeInDown animated"
                                     data-animation="fadeInDown" data-delay=".4s">
                                    <h5>Besoin de nos services ?</h5>
                                    <h2>
                                        Contactez-nous dès maintenant!
                                    </h2>
                                    <p>Ne tardez pas, notre planning se remplit très vite! Faites votre demande dès
                                        maintenant en cliquant sur le bouton ci-dessous.</p>
                                </div>
                                <Link to="/contact" className="btn ss-btn smoth-scroll"><i className={"fal fa-rocket"}
                                                                                           style={{marginRight: "7px"}}></i> Demande
                                    de devis</Link>

                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default CTA