import React from 'react'
import {Link} from 'react-router-dom'
import Logo from '../../assets/images/logo/logo.png'

const Footer = () => {
    return (
        <>
            <footer className="footer-bg footer-p">
                <div className="footer-top  pt-90 pb-40"
                     style={{background: "rgb(25, 25, 25)"}}>
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <div className="footer-widget mb-30">
                                    <div className="f-widget-title mb-30">
                                        <img src={Logo} alt="img"/>
                                    </div>
                                    <p>Faites briller vos soirées événementielles grâce à nos services ou louez des
                                        équipements de sonorisation avec l'expertise de chez DJ Len.</p>
                                    <br/>
                                    <small>#DJLen #Evenementiel #Soirée #Location #Gala #Mariage #Bal #Anniversaire
                                        #Lyon #Vienne #Givors #SaintMichelSurRhone
                                        #Valence</small>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-sm-6">
                                <div className="footer-widget mb-30">
                                    <div className="f-widget-title">
                                        <h2>Coordonnées</h2>
                                    </div>
                                    <div className="f-contact">
                                        <ul>
                                            <li>
                                                <i className="icon fal fa-phone"></i>
                                                <p style={{marginTop: "10px", marginBottom: "0px"}}>
                                                    <a href="tel:0652346814">+33 6 52 34 68 14</a>
                                                </p>
                                            </li>
                                            <li>
                                                <i className="icon fal fa-envelope"></i>
                                                <p style={{marginTop: "10px", marginBottom: "0px"}}>
                                                    <a href="mailto:contact@djlen.fr?subject=Demande via djlen.fr">contact@djlen.fr</a>
                                                </p>
                                            </li>
                                            <li>
                                                <i className="icon fal fa-map-marker-check"></i>
                                                <p style={{marginTop: "10px", marginBottom: "0px"}}>
                                                    Saint-Michel-Sur-Rhône
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-3 col-sm-6">
                                <div className="footer-widget mb-30">
                                    <div className="f-widget-title">
                                        <h2>Liens utiles</h2>
                                    </div>
                                    <div className="footer-link">
                                        <ul>
                                            <li><Link to="/mentions-legales">Mentions légales</Link></li>
                                            <li><Link to="/politique-de-confidentialitee">Politique de
                                                confidentialitée</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-wrap">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-6">
                                Copyright &copy; DJ Len 2024. Tous droits réservés. Développé par <a target={"_blank"} rel="noopener noreferrer"
                                                                                                     style={{color: 'rgb(201, 201, 201)'}}
                                                                                                     href="//florianvieira.fr">Florian
                                VIEIRA</a>.
                            </div>
                            <div className="col-lg-6 col-md-6 text-right text-xl-right">
                                <div className="footer-social">
                                    <Link target={"_blank"} to="//instagram.com/dj_len_42"><i
                                        className="fab fa-instagram"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
