import Bradcom from '../Bradcom/Main'
import Location1 from "../../assets/images/locations/sparkular.JPG";
import CTA from "../CTA/CTA";
import {Link} from 'react-router-dom'
import React from 'react'

const Main = () => {
    return (

        <>
            <main>
                <Bradcom title={"Location"}
                         subtitle={"Un besoin de location de matériel pour un événement? DJ Len est là !"}/>

                <section id="services" className="services-area pt-120 pb-90">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-4 col-md-6">
                                <div className="single-services ser-m mb-30">
                                    <div className="services-thumb">
                                        <Link to="/location/machine-etincelles-froides" className="gallery-link popup-image">
                                            <img src={Location1} alt="img"/>
                                        </Link>
                                    </div>
                                    <div className="services-content">
                                        <div className="day-book">
                                            <ul>
                                                <li>À partir de</li>
                                                <li>90,00 € TTC</li>
                                            </ul>
                                        </div>
                                        <h4><Link to="/location/machine-etincelles-froides">Machine à étincelles froides</Link></h4>
                                        <p>Parfaite pour sublimer vos mariages, fêtes, anniversaires et concerts, cette
                                            effet vous garantit une expérience inoubliable.</p>
                                        <div className="icon">
                                            <Link className="btn ss-btn smoth-scroll" style={{display: 'block'}}
                                                  to="/location/machine-etincelles-froides">+ d'infos</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </section>

                <CTA/>
            </main>
        </>
    )
}

export default Main