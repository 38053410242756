import React, {useEffect, useState} from 'react'
import {Navigate, Route, Routes, useLocation} from 'react-router-dom'
import Navbar from '../component/Layouts/Navbar'
import Footer from '../component/Layouts/Footer'
import Home from '../component/Home/Main'
import About from '../component/About/Main'
import Service from '../component/Services/Main'
import ServiceBalGala from '../component/Services/BalGala/Main'
import ServiceMariage from '../component/Services/Mariage/Main'
import ServiceAnniversaire from '../component/Services/Anniversaire/Main'
import Galerie from '../component/Galerie/Main'
import Contact from '../component/Contact/Main'
import Location from '../component/Location/Main'


import Legal from '../component/Legals/Main'
import PolicyConfidentiality from '../component/Confidentiality/Main'
import Sparkular from "../component/Location/Details/Sparkular";


const Routing = () => {
    const [homepage, sethomepage] = useState(false)
    const location = useLocation();
    useEffect(() => {
        if (location.pathname === "/home-03" || location.pathname === "/home-04") {
            sethomepage(false)
        } else {
            sethomepage(true)
        }
    }, [location])

    const [footerpage, setfooterpage] = useState(false)
    useEffect(() => {
        if (location.pathname === "/home-03") {
            setfooterpage(false)
        } else {
            setfooterpage(true)
        }
    }, [location])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            {homepage && <Navbar/>}
            <Routes>

                <Route path="/" element={<Home/>}/>
                <Route path="/a-propos" element={<About/>}/>

                <Route path="/prestations" element={<Service/>}/>
                <Route path="/prestations/bals-galas" element={<ServiceBalGala/>}/>
                <Route path="/prestations/mariages" element={<ServiceMariage/>}/>
                <Route path="/prestations/anniversaires" element={<ServiceAnniversaire/>}/>

                <Route path="/contact" element={<Contact/>}/>

                <Route path="/location" element={<Location/>}/>
                <Route path="/location/machine-etincelles-froides" element={<Sparkular/>}/>


                <Route path="/galerie" element={<Galerie/>}/>


                <Route path="/mentions-legales" element={<Legal/>}/>
                <Route path="/politique-de-confidentialitee" element={<PolicyConfidentiality/>}/>

                <Route path="*" element={<Navigate to="/"/>}/>
            </Routes>
            {footerpage && <Footer/>}
        </>
    )
}

export default Routing