import {Link} from 'react-router-dom'
import React from 'react'
import Bradcom from '../Bradcom/Main'
import Bg from "../../assets/images/services/bg.png";
import Anniversaire from "../../assets/images/services/anniversaire.png";
import Mariage from "../../assets/images/services/mariage.png";
import Bal from "../../assets/images/services/bal.png";
import Entreprise from "../../assets/images/services/entreprise.png";
import SoireePrivee from "../../assets/images/services/soiree-privee.png";
import CTA from "../CTA/CTA";

const Main = () => {
    return (
        <>
            <main>
                <Bradcom title={"Prestations"}
                         subtitle={"Ci-dessous vous trouverez nos différentes prestations."}/>

                <section id="service-details2" className="pt-120 pb-90 p-relative">
                    <div className="animations-01"><img src={Bg} alt="an-img-01"/></div>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-4 col-md-6">
                                <div className="services-08-item mb-30">
                                    <div className="services-icon2">
                                        <img src={Anniversaire} alt="img"/>
                                    </div>
                                    <div className="services-08-thumb">
                                        <img src={Anniversaire} alt="img"/>
                                    </div>
                                    <div className="services-08-content">
                                        <h3><Link to="/prestations/anniversaires">Anniversaires</Link></h3>
                                        <Link to="/prestations/anniversaires">Voir <i
                                            className="fal fa-long-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="services-08-item mb-30">
                                    <div className="services-icon2">
                                        <img src={Mariage} alt="img"/>
                                    </div>
                                    <div className="services-08-thumb">
                                        <img src={Mariage} alt="img"/>
                                    </div>
                                    <div className="services-08-content">
                                        <h3><Link to="/prestations/mariages">Mariages</Link></h3>
                                        <Link to="/prestations/mariages">Voir <i
                                            className="fal fa-long-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="services-08-item mb-30">
                                    <div className="services-icon2">
                                        <img src={Bal} alt="img"/>
                                    </div>
                                    <div className="services-08-thumb">
                                        <img src={Bal} alt="img"/>
                                    </div>
                                    <div className="services-08-content">
                                        <h3><Link to="/prestations/bals-galas"> Bals / Galas</Link></h3>
                                        <Link to="/prestations/bals-galas">Voir <i
                                            className="fal fa-long-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="section-title center-align mb-40 text-center wow fadeInDown animated"
                                     data-animation="fadeInDown" data-delay=".4s"><br/><br/>
                                    <h3 style={{textTransform: "initial", opacity: '0.7'}}>Mais également pour vos
                                        prestations ...</h3><br/>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className="services-08-item mb-30">
                                    <div className="services-icon2">
                                        <img src={Entreprise} alt="img"/>
                                    </div>
                                    <div className="services-08-thumb">
                                        <img src={Entreprise} alt="img"/>
                                    </div>
                                    <div className="services-08-content">
                                        <h3>Séminaires</h3>
                                        <p>Événement de lancement, séminaires d'entreprises, Congrés...</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className="services-08-item mb-30">
                                    <div className="services-icon2">
                                        <img src={SoireePrivee} alt="img"/>
                                    </div>
                                    <div className="services-08-thumb">
                                        <img src={SoireePrivee} alt="img"/>
                                    </div>
                                    <div className="services-08-content">
                                        <h3>Soirée privées</h3>
                                        <p>Soirée privées, ocassion particulière...</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                <CTA/>
            </main>
        </>
    )
}

export default Main