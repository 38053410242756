import React from 'react'
import Bradcom from '../Bradcom/Main'

const Main = () => {
    return (
        <>
            <main>
                <Bradcom title={"Politique de confidentialitée"}
                         subtitle={"Toutes les informations concernant notre politique de confidentialitée sont regroupées ici !"}/>

                <section id="cookies" className="after-none contact-bg pt-120 pb-120 p-relative fix">
                    <div className="container">
                        <p>La présente politique de confidentialité repose sur le Règlement UE 2016/679 relatif à la
                            protection des données (RGPD) et sur votre consentement éclairé. Son but est de vous
                            renseigner sur le traitement de vos données personnelles. Bien que cette politique
                            s'applique uniquement aux informations collectées par ce site, il est possible qu'il
                            comporte des liens vers d'autres sites externes. Nous ne pouvons garantir que ces sites
                            suivent la même politique de confidentialité que la nôtre, c'est pourquoi nous vous
                            recommandons de consulter les politiques de confidentialité des sites que vous pourriez
                            visiter à partir du nôtre avant de fournir vos données personnelles.</p>
                        <p>Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment,
                            ces modifications prenant effet immédiatement. En utilisant ce site à chaque nouvelle
                            occasion, vous acceptez la politique de confidentialité en vigueur. Nous vous encourageons
                            donc à consulter régulièrement cette page pour maintenir votre consentement.</p><br/>
                        <h4>Qui est le responsable du Site&nbsp;?</h4>
                        <p>Le Site est édité par la société DJ LEN ayant son siège social à Saint Michel sur Rhône et
                            ayant la qualité de responsable de traitement. Pour toute question ou
                            démarche relative à la collecte et au traitement de vos données à
                            caractère personnel, merci de nous contacter à l’adresse suivante contact@djlen.fr
                            .</p><br/>
                        <h4>Quels données sont collectés ?</h4>
                        <p>Lors de votre visite sur notre site, votre identité reste confidentielle. Les données
                            recueillies pendant votre navigation sont utilisées pour analyser votre comportement sur le
                            site et améliorer votre expérience utilisateur. Les informations personnelles telles que
                            votre nom, prénom, adresse postale, numéro de téléphone ou adresse e-mail ne sont collectées
                            que si vous consentez à les fournir. Par exemple, cela peut se produire lorsque vous vous
                            inscrivez à notre newsletter, que vous demandez un devis ou des informations, ou lorsque
                            vous souhaitez être contacté(e). Nous nous engageons à collecter uniquement les informations
                            strictement nécessaires au traitement de votre demande. En prenant des mesures pour nous
                            contacter, vous confirmez l'exactitude des données personnelles que vous communiquez.</p>
                        <br/>
                        <h4>Pourquoi collectons-nous vos données personnelles ?</h4>
                        <p>Nous recueillons vos données personnelles dans le but de gérer et de maintenir la relation
                            client, ce qui inclut la gestion des demandes d'informations, des devis, le traitement des
                            requêtes, la communication d'offres commerciales ou de newsletters, l'amélioration de la
                            navigation sur notre site, l'optimisation du développement du site, la personnalisation des
                            services, l'authentification des données transmises, la prévention des incidents de sécurité
                            ou des fraudes, etc. La collecte de ces données à caractère personnel repose à la fois sur
                            nos obligations légales et sur votre consentement.</p><br/>
                        <h4>Comment vos données sont-elles utilisées ?</h4>
                        <p>Les données personnelles que vous nous communiquez sont traitées par notre société en tant
                            que responsable du traitement du site. Nous nous engageons à garantir la stricte
                            confidentialité de vos données personnelles et à les utiliser exclusivement aux fins pour
                            lesquelles vous avez accepté de nous les fournir. Ces données ne seront toutefois pas
                            conservées au-delà de la période nécessaire, c'est-à-dire pendant toute la durée de notre
                            collaboration, augmentée du délai de prescription légale. Une fois cette période écoulée,
                            les données seront détruites.
                            Il est possible que nous ayons besoin de transférer ces données à des tiers sous-traitants,
                            tels que notre prestataire informatique ou notre hébergeur, qui peuvent être situés au sein
                            ou en dehors de l'Union européenne. Nous nous assurons que ces transferts respectent la
                            présente politique, en conformité avec les clauses contractuelles types édictées par la
                            Commission européenne pour nos prestataires hors de l'UE. Cela garantit un niveau de
                            protection équivalent et approprié, dans la mesure strictement nécessaire à l'exécution de
                            leurs services.</p>
                        <br/>
                        <h4>Pendant combien de temps sont-elles conservées ?</h4>
                        <p>Les données personnelles collectées et traitées par le site sont conservées pendant la
                            période nécessaire à leur finalité, telle qu'exposée précédemment, et conformément aux
                            délais légaux requis pour répondre à toute obligation légale ou réglementaire, ainsi que
                            pour engager ou répondre à tout litige.</p>
                        <br/>
                        <h4>Vos données sont-elles protégées ?</h4>
                        <p>En tant que responsable du traitement, nous mettons en place des mesures techniques et
                            organisationnelles appropriées, conformément aux obligations légales en vigueur, afin de
                            garantir la protection de vos données personnelles contre toute altération, perte
                            accidentelle ou illicite, utilisation non autorisée, divulgation ou accès non autorisé. Ces
                            mesures incluent notre politique générale de sécurité informatique, la sensibilisation de
                            nos collaborateurs aux exigences de confidentialité, la sécurisation de nos locaux et outils
                            informatiques, ainsi que la sélection rigoureuse de nos partenaires et sous-traitants.</p>
                        <br/>
                        <h4>Quels sont vos droits ?</h4>
                        <p>Vous avez le droit de choisir de ne pas nous fournir vos données personnelles. Dans ce cas,
                            vous pouvez naviguer sur ce site, mais vous n'aurez pas accès à toutes ses fonctionnalités,
                            telles que l'adhésion à la newsletter ou la demande d'être recontacté(e). Vous disposez
                            également d'un droit d'accès, de correction, de retrait et de limitation des traitements de
                            vos données personnelles. Pour exercer ces droits, vous pouvez les mettre à jour, demander
                            des corrections, des compléments, des mises à jour ou le verrouillage, ou demander à être
                            retiré(e) de notre liste de diffusion en nous contactant à l'adresse suivante :
                            contact@djlen.fr.

                            Si nécessaire, vous avez le droit de déposer une réclamation auprès de la CNIL, dont les
                            modalités d'exécution sont disponibles sur leur site internet (www.cnil.fr). Cependant, nous
                            vous encourageons à nous contacter au préalable pour trouver une solution.

                            Par ailleurs, vous avez le droit à la portabilité de vos données personnelles. Vous pouvez
                            demander à ce que nous vous restituions, à la même adresse précédente, toutes les données
                            personnelles que vous nous avez fournies, par exemple pour les transférer à un tiers.

                            Enfin, vous avez le droit de vous opposer au traitement de vos données pour des motifs
                            légitimes, ainsi que le droit de vous opposer à ce que ces données soient utilisées à
                            d'autres fins que celles auxquelles vous avez consenti(e). Vous pouvez exercer ce droit en
                            contactant le responsable de traitement à l'adresse mentionnée ici : contact@djlen.fr.</p>
                        <br/><br/><br/><br/>

                        <p>Pour toutes questions cernant cette politique de confidentialité ou vos données à caractère
                            personnel, n’hésitez pas à nous contacter à <b>contact@djlen.fr</b>.</p>

                    </div>
                </section>
            </main>
        </>
    )
}

export default Main