import React, {useState, useEffect} from 'react'
import {Link, useLocation} from 'react-router-dom'
import Logo from '../../assets/images/logo/logo.png'

const Navbar = () => {

    const [mobile, setmobile] = useState(false)
    const [show, setshow] = useState(false)

    const location = useLocation()
    const path = location.pathname

    useEffect(() => {
        window.scroll(0, 0)
    }, [path]);

    return (
        <>
            <header className="header-area header-three">
                <div id="header-sticky" className="menu-area">
                    <div className="container">
                        <div className="second-menu">
                            <div className="row align-items-center">
                                <div className="col-xl-2 col-lg-2">
                                    <div className="logo">
                                        <Link to="/"><img src={Logo} alt="logo"/></Link>
                                    </div>
                                </div>
                                <div className="col-xl-10 col-lg-10">
                                    <div className="main-menu text-right">
                                        <nav id="mobile-menu ">
                                            <ul>
                                                <li><Link to="/">Accueil</Link></li>
                                                <li><Link to="/a-propos">À propos</Link></li>
                                                <li className="has-sub">
                                                    <Link to="/prestations">Prestations</Link>
                                                    <ul>
                                                        <li><Link to="/prestations/mariages">Mariages</Link></li>
                                                        <li><Link to="/prestations/anniversaires">Anniversaires</Link>
                                                        </li>
                                                        <li><Link to="/prestations/bals-galas">Bals / Galas</Link></li>
                                                        <li><Link to="/prestations">Autres...</Link></li>
                                                    </ul>
                                                </li>
                                                <li><Link to="/location">Location</Link></li>
                                                <li><Link to="/galerie">Galerie</Link></li>
                                                <li><Link to="/contact">Contact</Link></li>
                                                <li><Link target={"_blank"} to="//instagram.com/dj_len_42"><i
                                                    className="fab fa-instagram"></i></Link></li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mobile-menu mean-container">
                                        <div className="mean-bar"><Link to="#nav"
                                                                        className={`meanmenu-reveal ${mobile && "meanclose"}`}
                                                                        onClick={() => setmobile(mobile === true ? false : true)}
                                                                        style={{
                                                                            right: "0px",
                                                                            left: "auto",
                                                                            textAlign: "center",
                                                                            textIndent: "0px",
                                                                            fontSize: "18px"
                                                                        }}>
                                            {mobile ?
                                                "X"
                                                :
                                                <>
                                                    <span><span><span></span></span></span>
                                                </>
                                            }
                                        </Link>
                                            <nav className="mean-nav">
                                                {mobile &&
                                                    <ul style={{display: mobile ? "block" : "none"}}>
                                                        <li><Link to="/">Accueil</Link></li>
                                                        <li><Link to="/a-propos">À propos</Link></li>
                                                        <li className="has-sub">
                                                            <Link to="/prestations">Prestations</Link>
                                                            <ul style={{display: show ? "block" : "none"}}>
                                                                <li><Link to="/prestations/mariages">Mariages</Link>
                                                                </li>
                                                                <li><Link
                                                                    to="/prestations/anniversaires">Anniversaires</Link>
                                                                </li>
                                                                <li><Link to="/prestations/bals-galas">Bals /
                                                                    Galas</Link></li>
                                                                <li><Link to="/prestations">Autres...</Link></li>
                                                            </ul>
                                                            <Link to="#"
                                                                  className={`mean-expand ${show && "mean-clicked"}`}
                                                                  onClick={() => setshow(show === true ? false : true)}
                                                                  style={{fontSize: "18px"}}>{show ? "-" : "+"}</Link>
                                                        </li>
                                                        <li><Link to="/location">Location</Link></li>
                                                        <li><Link to="/galerie">Galerie</Link></li>
                                                        <li><Link to="/contact">Contact</Link></li>

                                                    </ul>}
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Navbar