import Bradcom from '../Bradcom/Main'
import CTA from "../CTA/CTA";
import Bg from "../../assets/images/services/bg.png";
import ImageGallery from "react-image-grid-gallery";

const Main = () => {

    function importAll(r) {
        return r.keys().map((fileName) => ({
            src: fileName.replace(/^\.\//, "/assets/images/gallery/"),
            alt: 'DJ Len - Prestations'
        }));
    }

    const imagesContext = require.context('../../assets/images/gallery', false, /\.(png|jpe?g|svg)$/);
    const imagesT = importAll(imagesContext);

    return (
        <>
            <main>
                <Bradcom title={"Galerie"}
                         subtitle={"Découvrez les images de nos prestations, mariages, bals, gala..."}/>
                <section className="profile fix pt-120">
                    <div className="animations-01"><img src={Bg} alt="an-img-01"/></div>
                    <div className="container mb-120">
                        <ImageGallery imgArray={imagesT} columnWidth={230} gapSize={24}/>
                    </div>
                </section>

                <CTA/>
            </main>
        </>
    )
}

export default Main