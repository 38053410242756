import React from 'react'
import BGImg from "../../assets/images/bg/bdrc-bg.jpg";

import {seo} from "../../helpers/seo";

function Main({title, subtitle, newtitle}) {
    seo(title)


    return (
        <>
            <section className="breadcrumb-area d-flex align-items-center" style={{
                background: `url(${BGImg})`,
                backgroundPosition: 'bottom',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
            }}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-12 col-lg-12">
                            <div className="breadcrumb-wrap text-center">
                                <div className="breadcrumb-title">
                                    <h2 style={{marginBottom: "5px"}}>{title}</h2>
                                    <p style={{"color": "#FFF", "opacity": "0.8"}}>{subtitle}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Main