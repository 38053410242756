import React from 'react'
import Bradcom from '../Bradcom/Main'
import {seo} from "../../helpers/seo";

const Main = () => {
    return (<>
        <main>
            <Bradcom title={"Mentions légales"}
                     subtitle={"Toutes les informations légales sur notre société sont regroupées ici !"}/>

            <section id="legals" className="after-none contact-bg pt-120 pb-120 p-relative fix">
                <div className="container">

                    <h4>Informations</h4>
                    <p>
                        <b>Nom Société/Entreprise :</b> DJ Len<br/>
                        <b>Forme :</b> Auto Entreprise<br/>
                        <b>Représentée par :</b> Lenny COLOMBET<br/>
                        <b>Siège social :</b> Saint-Michel-sur-Rhone<br/>
                        <b>Directeur de la publication :</b> Florian VIEIRA<br/>
                        <b>Email :</b> contact@djlen.fr<br/>
                        <b>Téléphone :</b> 06 52 34 68 14
                    </p><br/>

                    <h4>Éditeur du site</h4>
                    <p>Florian VIEIRA est l'éditeur du site internet www.djlen.fr. Toute prise de contact avec le
                        créateur de ce site web se fait par voie électronique à l'adresse contact@florianvieira.fr.</p>
                    <br/>

                    <h4>Hébergement</h4>
                    <p>
                        www.djlen.fr est hébergé par la société OVH.<br/>
                        Adresse : 2 rue Kellermann - 59100 Roubaix - France.<br/>
                        OVH SAS agit en qualité d'hébergeur et ne peut en aucun cas être tenu responsable du contenu
                        de ce site.
                    </p><br/>
                    <h4>Description des services rendus</h4>
                    <p>Le site internet www.djlen.fr est un site vitrine où sont présentées les différentes
                        prestations de DJ LEN. Une prise de contact est possible via
                        le formulaire de contact.
                    </p><br/>
                    <h4>Propriété intellectuelle</h4>
                    <p>Le code source, le graphisme, les images, les sons, les animations, les textes, ainsi que les
                        informations et les contenus de www.florianvieira.fr sont protégés par les droits de la
                        propriété intellectuelle. La reproduction totale ou partielle de ce site web est interdite
                        sans autorisation expresse préalable et par écrit.
                    </p><br/>
                    <h4>Responsabilité</h4>
                    <p>La responsabilité de l’Editeur ne peut être engagée en cas de défaillance, panne ou
                        interruption empêchant l’accès au Site ou limitant son usage.Le matériel de connexion et de
                        navigation utilisée relève de la seule responsabilité de son
                        utilisateur, lequel doit prendre toutes les mesures appropriées pour protéger son matériel,
                        s’agissant notamment d’attaques virales.L’Editeur décline toute responsabilité résultant
                        d’une intrusion frauduleuse d’un tiers ayant
                        entrainé une modification des informations présentées sur le Site.</p><br/>
                    <h4>Droit applicable et attribution de juridiction</h4>
                    <p>Tout litige en relation avec l’utilisation du site est soumis au droit français. Il est fait
                        attribution exclusive de juridiction aux tribunaux compétents de Paris.</p>

                </div>
            </section>
        </main>
    </>)
}

export default Main