import {Link} from 'react-router-dom'
import React, {useState} from 'react'
import Bradcom from '../Bradcom/Main'
import axios from "axios";

const Main = () => {
    const queryParameters = new URLSearchParams(window.location.search)
    const type = queryParameters.get("type")
    const date = queryParameters.get("date")

    const [firstname, setFirstname] = useState("");
    const [email, setEmail] = useState("");
    const [writecomment, setWritecomment] = useState((date !== null || type !== null ? "Date événement : " + date + "\nType d'évenement : " + type + "\n\nDécrivez nous votre événement ici (lieu, horaires...) * :\n" : ""));
    const [phone, setPhone] = useState("");
    const [subject, setSubjet] = useState("");

    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const [bool, setBool] = useState(false);

    const handleSumbit = (e) => {
        e.preventDefault();

        setBool(true);

        let fData = new FormData();
        fData.append("name", firstname);
        fData.append("phone", phone);
        fData.append("email", email);
        fData.append("subject", subject);
        fData.append("message", writecomment);
        axios({
            method: "POST",
            url: "https://billing.djlen.fr/contact",
            data: fData,
            headers: {"Content-Type": "multipart/form-data"},
        }).then((response) => {
            if (response.data.success === undefined) {
                setSuccessMessage('')
                setErrorMessage(response.data.error);
            } else {
                setErrorMessage('')
                setSuccessMessage(response.data.success);

                setFirstname("");
                setEmail("");
                setWritecomment("");
                setPhone("");
                setSubjet("");
            }

            setBool(false);

        }).catch((error) => {
            setErrorMessage('Une erreur est survenue. Veuillez réessayer plus tard.');

            setBool(false);
        });

    };


    return (
        <>
            <main>
                <Bradcom title={"Contactez-nous !"}
                         subtitle={"Une question ? Une demande de devis ? Vous êtes au bon endroit!"}
                         newtitle={"Contact"}/>

                <section id="contact" className="contact-area after-none contact-bg pt-120 pb-120 p-relative fix">
                    <div className="container">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-lg-4 order-1">
                                <div className="contact-info">
                                    <div className="single-cta pb-30 mb-30 wow fadeInUp animated"
                                         data-animation="fadeInDown animated" data-delay=".2s">
                                        <div className="f-cta-icon">
                                            <i className="far fa-map"></i>
                                        </div>
                                        <h5>Addresse</h5>
                                        <p>Saint-Michel-Sur-Rhone</p>
                                    </div>
                                    <div className="single-cta pb-30 mb-30 wow fadeInUp animated"
                                         data-animation="fadeInDown animated" data-delay=".2s">
                                        <div className="f-cta-icon">
                                            <i className="far fa-clock"></i>
                                        </div>
                                        <h5>Horaires</h5>
                                        <p>Mardi au Vendredi de 14h30 à 19h00</p>
                                    </div>
                                    <div className="single-cta wow fadeInUp animated"
                                         data-animation="fadeInDown animated"
                                         data-delay=".2s">
                                        <div className="f-cta-icon">
                                            <i className="far fa-envelope-open"></i>
                                        </div>
                                        <h5>Téléphone</h5>
                                        <p><Link to="tel:0652346814">+33 6 52 34 68 14</Link></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 order-2">
                                <div className="contact-bg02">
                                    <form className="contact-form" onSubmit={handleSumbit}>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="contact-field p-relative c-name mb-20">
                                                    <input type="text" placeholder="Nom *" required value={firstname}
                                                           onChange={(e) => {
                                                               setFirstname(e.target.value)
                                                           }}/>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="contact-field p-relative c-subject mb-20">
                                                    <input type="email" placeholder="Email *" required value={email}
                                                           onChange={(e) => {
                                                               setEmail(e.target.value)
                                                           }}/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="contact-field p-relative c-subject mb-20">
                                                    <input type="number" placeholder="Téléphone (06XXXXXXXX) *" required
                                                           value={phone} onChange={(e) => {
                                                        setPhone(e.target.value)
                                                    }}/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="contact-field p-relative c-subject mb-20">
                                                    <input type="text" placeholder="Sujet *" value={subject}
                                                           onChange={(e) => {
                                                               setSubjet(e.target.value)
                                                           }}/>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="contact-field p-relative c-message mb-30">
                                                <textarea cols="30" rows="15"
                                                          placeholder="Décrivez nous votre demande ici (devis, questions, organisations...) *"
                                                          value={writecomment} onChange={(e) => {
                                                    setWritecomment(e.target.value)
                                                }}></textarea>
                                                </div>
                                            </div>

                                            <div className="col-lg-12">
                                                <div className="slider-btn">
                                                    <button className="btn ss-btn" data-animation="fadeInRight" disabled={bool}
                                                            data-delay=".8s" type="submit"><span>Envoyer</span>
                                                    </button>
                                                    {successMessage && <div><br/>
                                                        <div className="form-success" style={{
                                                            color: 'rgb(0, 156, 0)',
                                                            fontWeight: 500
                                                        }}><i
                                                            className="fal fa-check-circle fa-fade fa-2x"></i><br/> {successMessage}
                                                        </div>
                                                    </div>}
                                                    {errorMessage && <div><br/>
                                                        <div className="form-error" style={{
                                                            color: 'rgb(156, 0, 0)',
                                                            fontWeight: 500
                                                        }}><i
                                                            className="fal fa-times-circle fa-fade fa-2x"></i><br/> {errorMessage}
                                                        </div>
                                                    </div>}

                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <br/>

                                <small style={{"opacity": 0.6}}>
                                    Nous nous engageons à ce que la collecte et le traitement de vos données effectuées
                                    à
                                    partir du présent site internet soit conforme à la loi informatique et libertés et
                                    au
                                    règlement général sur la protection des données personnelles (RGPD). Afin d’exercer
                                    vos
                                    droits, notamment d’accès, de correction ou de retrait de vos données personnelles
                                    collectées via ce formulaire, consultez notre <Link
                                    to={"/politique-de-confidentialitee"}>Politique de
                                    confidentialité</Link>.<br/><br/>
                                    Conformément à l’article L 223-2 du code de la consommation, nous vous informons que
                                    vous pouvez à toute moment vous inscrire sur le site BLOCTEL sur la liste
                                    d’opposition
                                    au démarchage téléphonique.
                                </small>
                            </div>
                        </div>
                    </div>
                </section>

            </main>


        </>
    )
}

export default Main