import React from 'react'
import BGTest from "../../assets/images/bg/bg-testimonial.jpg";
import Aicon from "../../assets/images/testimonial/qt-icon.png"
import Aicon1 from "../../assets/images/testimonial/review-icon.png"
import Slider from "react-slick"

function Testimonial() {

    const testimonial = {
        dots: true,
        infinite: true,
        arrows: false,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    return (
        <>
            <section className="testimonial-area pt-120 pb-90 p-relative fix"
                     style={{
                         backgroundImage: `url(${BGTest})`,
                         backgroundPosition: 'bottom',
                         backgroundRepeat: 'no-repeat',
                         backgroundSize: 'cover'
                     }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title center-align mb-50 text-center">
                                <h5 style={{color: "#FFFFFF"}}>Témoignages</h5>
                                <h2 style={{color: "#FFFFFF"}}>Ils nous ont fait confiances !</h2>
                                <p style={{color: "#FFFFFF", opacity: "0.6"}}>Découvrez les impressions et les avis de
                                    nos clients.</p>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <Slider className="testimonial-active" {...testimonial}>
                                <div className="single-testimonial">
                                    <div className="testi-author">
                                        <div className="ta-info">
                                            <h6>Mireille ROSSI</h6>
                                        </div>
                                    </div>
                                    <div className="review-icon">
                                        <img src={Aicon1} alt="img"/>
                                    </div>
                                    <p>“Au top ! Vraiment ! Très bonne musique”</p>

                                    <div className="qt-img">
                                        <img src={Aicon} alt="img"/>
                                    </div>
                                </div>
                                <div className="single-testimonial">
                                    <div className="testi-author">
                                        <div className="ta-info">
                                            <h6>Francine Raminez</h6>
                                        </div>
                                    </div>
                                    <div className="review-icon">
                                        <img src={Aicon1} alt="img"/>
                                    </div>
                                    <p>”Un DJ très pro avec de la bonne musique. L'ambiance et les titres sélectionnées
                                        étaient géniaux.”</p>

                                    <div className="qt-img">
                                        <img src={Aicon} alt="img"/>
                                    </div>
                                </div>
                                <div className="single-testimonial">
                                    <div className="testi-author">
                                        <div className="ta-info">
                                            <h6>Manon Reynaud</h6>
                                        </div>
                                    </div>
                                    <div className="review-icon">
                                        <img src={Aicon1} alt="img"/>
                                    </div>
                                    <p>“Un DJ au top qui est à l'écoute de nos envies. Nous avons passés une super
                                        soirée ! Encore merci ☺️”</p>

                                    <div className="qt-img">
                                        <img src={Aicon} alt="img"/>
                                    </div>
                                </div>
                                <div className="single-testimonial">
                                    <div className="testi-author">
                                        <div className="ta-info">
                                            <h6>Isabelle GONTERO-CARAS</h6>
                                            <span>Mariage</span>
                                        </div>
                                    </div>
                                    <div className="review-icon">
                                        <img src={Aicon1} alt="img"/>
                                    </div>
                                    <p>“DJ LEN est au top. A l écoute, met une très très bonne ambiance. C est le
                                        meilleur DJ. Nous l avons pris en Juillet 2023 pour les 20 ans de notre fille.
                                        Tous les jeunes ont adorés sa préstation et se sont amusés durant toute la
                                        soirée.”</p>

                                    <div className="qt-img">
                                        <img src={Aicon} alt="img"/>
                                    </div>
                                </div>
                                <div className="single-testimonial">
                                    <div className="testi-author">
                                        <div className="ta-info">
                                            <h6>Fred Naël Vernay</h6>
                                        </div>
                                    </div>
                                    <div className="review-icon">
                                        <img src={Aicon1} alt="img"/>
                                    </div>
                                    <p>“Nous avons choisis dj len pour notre mariage le 03 Juin 2023 nous avons été plus
                                        que content de sa prestation, choix musical abiance juste parfaite. Je le
                                        recommande à 100000% une personne très professionel à l'écoute de ses
                                        clients pour le choix et la diversité des musiques. Encore un énorme merci à toi
                                        pour avoir mis le feu pour cette magnifique journée. ☺️Continue comme ça !”</p>

                                    <div className="qt-img">
                                        <img src={Aicon} alt="img"/>
                                    </div>
                                </div>
                                <div className="single-testimonial">
                                    <div className="testi-author">
                                        <div className="ta-info">
                                            <h6>Gammerdu94_wide</h6>
                                        </div>
                                    </div>
                                    <div className="review-icon">
                                        <img src={Aicon1} alt="img"/>
                                    </div>
                                    <p>“Super dj. Nous avons fait appel à ses services, nous sommes très content. Je le
                                        recommande c'est le meilleur !”</p>

                                    <div className="qt-img">
                                        <img src={Aicon} alt="img"/>
                                    </div>
                                </div>
                                <div className="single-testimonial">
                                    <div className="testi-author">
                                        <div className="ta-info">
                                            <h6>Océane D.</h6>
                                        </div>
                                    </div>
                                    <div className="review-icon">
                                        <img src={Aicon1} alt="img"/>
                                    </div>
                                    <p>“DJ Incroyable avec un grand savoir-faire. À l'écoute des clients, je vous le
                                        conseil fortement !!”</p>

                                    <div className="qt-img">
                                        <img src={Aicon} alt="img"/>
                                    </div>
                                </div>
                                <div className="single-testimonial">
                                    <div className="testi-author">
                                        <div className="ta-info">
                                            <h6>Marie-Pierre DA.</h6>
                                        </div>
                                    </div>
                                    <div className="review-icon">
                                        <img src={Aicon1} alt="img"/>
                                    </div>
                                    <p>“Avec DJ Len les soirées sont au top. Il s'est s'adapter à son public toujours prêt à faire plaisir. D'une extrême gentillesse. Je vous le recommande!”</p>

                                    <div className="qt-img">
                                        <img src={Aicon} alt="img"/>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Testimonial