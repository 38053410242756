import React from 'react'
import Slider from "react-slick"
import PizzeriaDellanona from '../../assets/images/brand/pizzeria-dellanona.png'
import RCF from "../../assets/images/brand/rcf.png"
import Pioneer from "../../assets/images/brand/b-logo4.png"
import HK from "../../assets/images/brand/hk.png"
import Shure from "../../assets/images/brand/shure.png"
import Brand9 from "../../assets/images/brand/b-logo9.png"
import Brand10 from "../../assets/images/brand/b-logo10.png"
import Brand11 from "../../assets/images/brand/b-logo11.png"
import Brand12 from "../../assets/images/brand/b-logo12.png"


function Brand() {

    const brand = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 900,
        autoplay: true,
        slidesToShow: 4,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }


    return (
        <>
            <div className="brand-area pt-60 pb-60" style={{background: "#00000012"}}>
                <div className="container">
                    <Slider className="row brand-active" {...brand}>
                        <div className="col-xl-2">
                            <div className="single-brand">
                                <img src={PizzeriaDellanona} alt="PizzeriaDellanona"/>
                            </div>
                        </div>
                        <div className="col-xl-2">
                            <div className="single-brand">
                                <img src={RCF} alt="RCF"/>
                            </div>
                        </div>
                        <div className="col-xl-2">
                            <div className="single-brand">
                                <img src={Pioneer} alt="Pioneer"/>
                            </div>
                        </div>
                        <div className="col-xl-2">
                            <div className="single-brand">
                                <img src={HK} alt="HK Audio"/>
                            </div>
                        </div>
                        <div className="col-xl-2">
                            <div className="single-brand">
                                <img src={Shure} alt="Shure"/>
                            </div>
                        </div>
                        <div className="col-xl-2">
                            <div className="single-brand">
                                <img src={Brand9} alt="Stairville"/>
                            </div>
                        </div>
                        <div className="col-xl-2">
                            <div className="single-brand">
                                <img src={Brand10} alt="Evolite"/>
                            </div>
                        </div>
                        <div className="col-xl-2">
                            <div className="single-brand">
                                <img src={Brand11} alt="Martin"/>
                            </div>
                        </div>
                        <div className="col-xl-2">
                            <div className="single-brand">
                                <img src={Brand12} alt="AFX Light"/>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        </>
    )
}

export default Brand