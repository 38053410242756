import React, {useState} from 'react'
import BGImg from "../../assets/images/home/bg-header-1.png";
import BGImg2 from "../../assets/images/home/bg-header-2.png";
import SliderReact from "react-slick"

function Slider() {

    const [animation] = useState()

    const home = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 2000,
        autoplay: false,
        slidesToShow: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    return (
        <>
            <section id="home" className="slider-area fix p-relative">
                <SliderReact className="slider-active" {...home}>
                    <div className="col-xl-2">
                        <div className="single-slider slider-bg d-flex align-items-center"
                             style={{backgroundImage: `url(${BGImg2})`}}>
                            <div className="container">
                                <div className="row justify-content-center align-items-center">
                                    <div className="col-lg-7 col-md-7">
                                        <div className="slider-content s-slider-content mt-80 text-center">
                                            <h2 className={`${animation && "animation" && "fadeInUp"} `}
                                                data-animation="fadeInUp" data-delay=".4s"
                                                style={{animationDelay: "0.4s"}}>DJ LEN</h2>
                                            <p data-animation="fadeInUp" data-delay=".6s">Événementiel — Sonorisation —
                                                Éclairage — DJ</p>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2">
                        <div className="single-slider slider-bg d-flex align-items-center"
                             style={{backgroundImage: `url(${BGImg})`, background_size: "cover"}}>
                            <div className="container">
                                <div className="row justify-content-center align-items-center">
                                    <div className="col-lg-7 col-md-7">
                                        <div className="slider-content s-slider-content mt-80 text-center">
                                            <h2 className={`${animation && "animation" && "fadeInUp"} `}
                                                data-animation="fadeInUp" data-delay=".4s"
                                                style={{animationDelay: "0.4s"}}>DJ LEN</h2>
                                            <p data-animation="fadeInUp" data-delay=".6s">Un peu de nous, beaucoup de vous ! ✨</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SliderReact>
            </section>

            <div id="booking" className="booking-area p-relative">
                <div className="container">
                    <form action="/contact" className="contact-form">
                    <div className="row align-items-center">
                            <div className="col-lg-12">
                                <ul>
                                    <li >
                                        <div className="contact-field p-relative c-name">
                                            <label><i className="fal fa-badge-check"></i> Quel est la date de votre
                                                événement ?</label>
                                            <input type="date" name="date"/>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="contact-field p-relative c-name">
                                            <label><i className="fal fa-bolt"></i>Type d'événement</label>
                                            <select name="type">
                                                <option value="-">-</option>
                                                <option value="Mariage">Mariage</option>
                                                <option value="Anniversaire">Anniversaire</option>
                                                <option value="Bal / Gala étudiant">BAL / Gala étudiant</option>
                                                <option value="Séminaire d'entreprise">Séminaire d'entreprise</option>
                                                <option value="Location matériel">Location matériel</option>
                                                <option value="Location matériel">Autres</option>
                                            </select>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="slider-btn" style={{"width": "100%"}}>
                                            <label><i style={{"color": "#000000a6"}}
                                                      className="fal fa-calendar-alt"></i></label>
                                            <button className="btn ss-btn" data-animation="fadeInRight"
                                                    style={{"width": "100%"}} data-delay=".8s">Demande de devis
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Slider