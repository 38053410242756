import React from 'react'
import Slider from './Slider';
import Most from './Most';
import Testimonial from './Testimonial';
import Brand from './Brand';
import {seo} from "../../helpers/seo";


const Main = () => {
    seo('Événementiel - Sonorisation - DJ')

    return (
        <>
            <main>
                <Slider/>
                <Most/>
                <Testimonial/>
                <Brand/>
            </main>

        </>
    )
}

export default Main