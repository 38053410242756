import React from 'react'
import Bradcom from '../../Bradcom/Main'
import Mariage1 from "../../../assets/images/services/mariage/1.jpg";
import MariageIcon from "../../../assets/images/bg/mariage-icon.png";
import CTA from "../../CTA/CTA";

const Main = () => {
    return (
        <>
            <main>
                <Bradcom title={"Prestations : Mariages"}
                         subtitle={"DJ Len à votre service !"}/>

                <section className="about-area about-p pt-120 pb-120 p-relative fix">
                    <div className="animations-02"><img src={MariageIcon} alt="an-img-01"/></div>

                    <div className="container">
                        <div className="row justify-content-center align-items-center">

                            <div className="col-lg-6 col-md-12 col-sm-12 pr-30">
                                <div className="s-about-img p-relative  wow fadeInLeft animated"
                                     data-animation="fadeInLeft" data-delay=".4s">
                                    <img style={{marginBottom: "0px", borderRadius: '25px'}} src={Mariage1} alt="img"/>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="feature-content s-about-content">
                                    <div className="feature-title pb-20">
                                        <h4>Un mariage innoubliable... ✨🥂</h4>
                                    </div>
                                    <p>Découvrez l'excellence de l'événementiel pour vos mariages avec DJ
                                        Len. Notre engagement est de vous offrir des solutions de sonorisation et
                                        d'éclairages innovantes et complètes, garantissant une soirée exceptionnelle.
                                    </p>

                                    <p>
                                        Comprendre l'importance de cet événement unique dans la vie d'un couple, nous
                                        mobilisons notre expertise et nos équipements pour créer une
                                        soirée de mariage mémorable, imprégnée de moments inoubliables.
                                    </p><br/>

                                    <p style={{ fontWeight: '500'}}>
                                        À la recherche d'une prestation sur mesure et exceptionnelle ? À l'écoute de vos
                                        besoins, nous vous accompagnons avec passion dans la réalisation de votre soirée
                                        de mariage, en accord avec vos préférences et votre budget.

                                    </p>

                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <CTA/>
            </main>
        </>
    )
}

export default Main