import Bradcom from '../../Bradcom/Main'
import Location1 from "../../../assets/images/locations/sparkular.png";
import Location2 from "../../../assets/images/locations/sparkular.JPG";

import CTA from "../../CTA/CTA";
import {Link} from 'react-router-dom'
import React from 'react'

const Main = () => {

    return (

        <>
            <main>
                <Bradcom title={"Location"}
                         subtitle={"Un besoin de location de matériel pour un événement? DJ Len est là !"}/>

                <div className="about-area5 about-p p-relative">
                    <div className="container pt-120 pb-40">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-4 order-2">
                                <aside className="sidebar services-sidebar">
                                    <div className="sidebar-widget categories">
                                        <div className="widget-content">
                                            <h2 className="widget-title"> Réservation </h2>
                                            <div className="booking">
                                                <div className="contact-bg">
                                                    Contactez-nous dès maintenant, en nous indiquant la période de
                                                    location et le nombre de machines souhaitées. <small>Nous vous
                                                    répondrons
                                                    dans un délais de 24h.</small><br/><br/><br/>

                                                    <Link to="/contact" className="btn ss-btn" style={{color: "#FFF"}}>Réserver
                                                        dès maintenant</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                            <div className="col-lg-8 col-md-12 col-sm-12 order-1">
                                <div className="service-detail">
                                    <div className="two-column">
                                        <div className="row">
                                            <div className="image-column col-xl-6 col-lg-12 col-md-12">
                                                <figure className="image"><img src={Location2}
                                                                               alt=""/></figure>
                                            </div>
                                            <div className="text-column col-xl-6 col-lg-12 col-md-12">
                                                <figure className="image"><img src={Location2}
                                                                               alt=""/></figure>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content-box">
                                        <div className="row align-items-center mb-50">
                                            <div className="col-lg-12">
                                                <div className="price">
                                                    <h2>Machine à étincelles froides</h2>
                                                    <span><small>À partir de</small> 90,00 € TTC</span>
                                                </div>
                                            </div>
                                        </div>
                                        <p>Ajoutez une touche magique à vos célébrations avec nos machines à étincelles
                                            froides, disponibles à la location dès maintenant ! Idéales pour une
                                            utilisation en intérieur ou en extérieur, ces machines vous offrent un
                                            spectacle époustouflant sans nécessiter aucune autorisation spéciale.</p>
                                        <ul className="green mb-30">
                                            <li style={{color: '#1D1D1D'}}>
                                                <i style={{fontWeight: '400', marginRight: '7px'}}
                                                   className="fal fa-check"></i> Etincelles froides ne pouvant pas
                                                provoquer des brûlures ou fumées.
                                            </li>
                                            <li style={{color: '#1D1D1D'}}>
                                                <i style={{fontWeight: '400', marginRight: '7px'}}
                                                   className="fal fa-check"></i> Déclenchement par télécommande
                                                sans fil <small>(ou DMX, 2 canaux)</small>
                                            </li>
                                            <li style={{color: '#1D1D1D'}}>
                                                <i style={{fontWeight: '400', marginRight: '7px'}}
                                                   className="fal fa-check"></i> Fourni avec la poudre de Titane
                                            </li>
                                        </ul>

                                        <ul className="room-features d-flex align-items-center">
                                            <li>
                                                <i className="fal fa-fire"></i> Ne brule pas
                                            </li>
                                            <li>
                                                <i className="fal fa-arrow-to-top"></i> 2-5m hauteur
                                            </li>
                                            <li>
                                                <i className="fal fa-gamepad"></i> Télécommande
                                            </li>
                                            <li>
                                                <i className="fal fa-computer-classic"></i> DMX
                                            </li>
                                            <li>
                                                <i className="fal fa-sparkles"></i> 6 machines
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <CTA/>
            </main>
        </>
    )
}

export default Main