import React from 'react'
import Bradcom from '../../Bradcom/Main'
import Anniversaire1 from "../../../assets/images/services/anniversaire/1.jpg";
import AnniversaireIcon from "../../../assets/images/bg/anniversaire-icon.png";
import CTA from "../../CTA/CTA";

const Main = () => {
    return (
        <>
            <main>
                <Bradcom title={"Prestations : Anniversaires"}
                         subtitle={"DJ Len à votre service !"}/>

                <section className="about-area about-p pt-120 pb-120 p-relative fix">
                    <div className="animations-02"><img src={AnniversaireIcon} alt="an-img-01"/></div>

                    <div className="container">
                        <div className="row justify-content-center align-items-center">

                            <div className="col-lg-6 col-md-12 col-sm-12 pr-30">
                                <div className="s-about-img p-relative  wow fadeInLeft animated"
                                     data-animation="fadeInLeft" data-delay=".4s">
                                    <img style={{marginBottom: "0px", borderRadius: '25px'}} src={Anniversaire1}
                                         alt="img"/>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="feature-content s-about-content">
                                    <div className="feature-title pb-20">
                                        <h4>Un anniversaire de folie... 🎈</h4>
                                    </div>
                                    <p>Préparez-vous à vivre un anniversaire sensationnel près de Vienne, Annonay,
                                        Givors, Valence et Lyon ! Notre équipe est prête à transformer votre événement
                                        en une expérience inoubliable. Des
                                        lumières envoûtantes à la musique entraînante, nous créons l'atmosphère parfaite
                                        pour satisfaire tous vos invités, des petits aux grands.<br/><br/>Faites de
                                        votre
                                        anniversaire un moment magique en nous contactant dès aujourd'hui !
                                    </p>

                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <CTA/>
            </main>
        </>
    )
}

export default Main