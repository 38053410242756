import React from 'react'
import Bradcom from '../Bradcom/Main'
import Bg from "../../assets/images/services/bg.png";
import About1 from "../../assets/images/about/about1.jpeg"
import About2 from "../../assets/images/about/about2.jpg"
import Camion from "../../assets/images/about/camion.png";
import CTA from "../CTA/CTA";

const Main = () => {
    return (
        <>
            <main>
                <Bradcom title={"À propos"} subtitle={"Qui sommes nous réellement ? Tout le détails ci-dessous !"}/>

                <section className="about-area about-p pt-120 pb-100 p-relative fix">
                    <div className="animations-06"><img src={Bg} alt="contact-bg-an-02"/></div>
                    <div className="container">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="s-about-img p-relative  wow fadeInLeft animated"
                                     data-animation="fadeInLeft" data-delay=".4s">
                                    <img src={About1} alt="img"  style={{borderRadius: '15px'}} width={'450px'}/>
                                    <div className="about-icon">
                                        <img src={About2} alt="img" style={{borderRadius: '15px'}} width={'350px'}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="about-content s-about-content  wow fadeInRight  animated pl-30"
                                     data-animation="fadeInRight" data-delay=".4s">
                                    <div className="about-title second-title pb-25">
                                        <h2>DJ Len c'est...</h2>
                                    </div>
                                    <p>
                                        Une jeune équipe formée, à l'écoute et motivée, est présente à vos côtés tout au
                                        long de votre événement. Nous vous accompagnons vers la réussite de votre
                                        projet, que ce soit pour vos mariages, anniversaires, week-ends d'intégration,
                                        séminaires ou tout autre événement festif ou sportif. Depuis septembre 2022, nous
                                        sommes là pour vous satisfaire. Nos solutions techniques allient design,
                                        puissance et garantissent une mise en place rapide et sécurisée.
                                    </p>
                                    <p> Notre matériel provient des plus grandes marques telles que HK Audio, RCF pour le
                                        système son, Pioneer DJ pour l'animation DJ, et même Stairville pour notre
                                        machine à brouillard, assurant ainsi une qualité irréprochable. Tous les
                                        projecteurs et jeux de lumières fonctionnent avec la technologie LED,
                                        garantissant une faible consommation d'énergie et une utilisation illimitée en
                                        toute sécurité.
                                    </p><br/>
                                    <p>
                                        Nous aspirons à une évolution constante en vous offrant le plus grand nombre
                                        possible de services.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="feature-area2 p-relative fix">
                    <div className="animations-09"><img src={Bg} alt="contact-bg-an-02"/></div>
                    <div className="container">
                    <div className="row justify-content-center align-items-center">
                            <div className="col-lg-6 col-md-12 col-sm-12 pr-30">
                                <div className="feature-img">
                                    <img src={Camion} alt="img" className="img"/>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="feature-content s-about-content">
                                    <div className="about-title second-title pb-25">
                                        <h3>Entre vous et nous, la distance n'est pas un obstacle...</h3>
                                    </div>
                                    <p>Nous croyons que la distance ne devrait jamais être un obstacle à la
                                        réalisation d'une prestation exceptionnelle. Que vous soyez à quelques pas
                                        de notre siège social ou à des kilomètres de distance, notre équipe est
                                        prête à vous accompagner avec le même enthousiasme et le même dévouement.
                                    </p><br/>
                                    <p>
                                        Nous sommes fiers de la confiance que nos clients nous accordent, et nous
                                        prenons cette responsabilité très au sérieux. En faisant appel à DJ Len,
                                        vous bénéficiez d'une équipe qui non seulement maîtrise son métier, mais qui
                                        crée également des liens humains authentiques. Notre engagement envers votre
                                        événement est total, quels que soient la taille, le lieu ou le type
                                        d'occasion.
                                    </p>

                                </div>
                            </div>
                    </div>
                    </div>
                </section>

                <CTA/>
            </main>
        </>
    )
}

export default Main